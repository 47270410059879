import { HandleException } from 'APP/helpers/ErrorHelper';
// eslint-disable-next-line import/no-cycle
import AuthAction from './AuthAction';
// eslint-disable-next-line import/no-cycle
import PasswordAction from './PasswordAction';
import { aboutToExpireTime } from '../config/auth';
import Toast from 'APP/common/Toast';
import dateHelpers from 'APP/helpers/DateHelper';

export const VALIDATE_SUCCESS = 'VALIDATE_SUCCESS';
export const VALIDATE_REQUEST = 'VALIDATE_REQUEST';
export const VALIDATE_FAILURE = 'VALIDATE_FAILURE';

export const PASSWORD_CHANGE_REQUEST = 'PASSWORD_CHANGE_REQUEST';
export const PASSWORD_CHANGE_FAILURE = 'PASSWORD_CHANGE_FAILURE';
export const PASSWORD_CHANGE_SUCCESS = 'PASSWORD_CHANGE_SUCCESS';

export const GENERATE_PASSWORD_REQUEST = 'GENERATE_PASSWORD_REQUEST';
export const GENERATE_PASSWORD_FAILURE = 'GENERATE_PASSWORD_FAILURE';
export const GENERATE_PASSWORD_SUCCESS = 'GENERATE_PASSWORD_SUCCESS';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const IS_UNAUTHED = 'IS_UNAUTHED';
export const SET_AUTH_STATE = 'SET_AUTH_STATE';

function sessionTimer(expire) {
    return dispatch => {
        const time = (expire - aboutToExpireTime) * 1000;
        // Set a timer to trigger authState change
        setTimeout(() => {
            dispatch(setAuthState('aboutToExpire'));
        }, time);
    };
}

function validationRequest() {
    return {
        type: VALIDATE_REQUEST,
    };
}

function validationSuccess(authData) {
    return {
        type: VALIDATE_SUCCESS,
        authData,
    };
}

function validationFailure(error) {
    return {
        type: VALIDATE_FAILURE,
        errorMessage: error,
    };
}

function isUnAuthed() {
    return {
        type: IS_UNAUTHED,
    };
}
// Refresh is used by automatic token update, to not tigger isFetching behaviour
export function validateJWT(refresh = false) {
    return dispatch => {
        if (!refresh) {
            dispatch(validationRequest());
        }
        return AuthAction.validateToken()
            .then(response => {
                dispatch(validationSuccess(response));
                dispatch(sessionTimer(response.expire));
            })
            .catch(error => {
                if (error.statusCode > 403) {
                    HandleException(error);
                    dispatch(validationFailure(error.message));
                    return;
                }
                dispatch(isUnAuthed());
            });
    };
}

function loginRequest() {
    return {
        type: LOGIN_REQUEST,
    };
}

function loginFailure(error) {
    return {
        type: LOGIN_FAILURE,
        errorMessage: error,
    };
}

export function loginUser(credentials) {
    return dispatch => {
        dispatch(loginRequest());
        return AuthAction.validateUser(credentials)
            .then(response => {
                if (response.user.password_expires_at) {
                    const aboutToExpire = dateHelpers.isAboutToExpire(response.user.password_expires_at);
                    if (aboutToExpire) {
                        Toast('warning-password', 'Password is soon expired. Change password!');
                    }
                }

                dispatch(validationSuccess(response));
                dispatch(sessionTimer(response.expire));
            })
            .catch(e => {
                HandleException(e);
                dispatch(loginFailure(e.message));
            });
    };
}

function passwordChangeFailure(error) {
    return {
        type: PASSWORD_CHANGE_FAILURE,
        errorMessage: error,
    };
}

function passwordChangeSuccess() {
    return {
        type: PASSWORD_CHANGE_SUCCESS,
    };
}

export function changePasswordUser(credentials) {
    return dispatch => {
        return PasswordAction.changeUserPassword(credentials)
            .then(() => {
                dispatch(passwordChangeSuccess());
                Toast('info', 'Password successfully changed!');
            })
            .catch(e => {
                HandleException(e);
                dispatch(passwordChangeFailure(e.message));
            });
    };
}

function generatePasswordFailure(error) {
    return {
        type: GENERATE_PASSWORD_FAILURE,
        errorMessage: error,
    };
}

function generatePasswordSuccess(password) {
    return {
        type: GENERATE_PASSWORD_SUCCESS,
        generatedPassword: password,
    };
}

export function generatePassword() {
    return dispatch => {
        return PasswordAction.generatePassword()
            .then(response => {
                dispatch(generatePasswordSuccess(response.data.data));
                Toast('info', 'Password generated and copied to clipboard!');
            })
            .catch(e => {
                HandleException(e);
                dispatch(generatePasswordFailure(e.message));
            });
    };
}

function requestLogout() {
    return {
        type: LOGOUT_REQUEST,
    };
}

function logoutSuccess() {
    return {
        type: LOGOUT_SUCCESS,
    };
}

function logoutFailure() {
    return {
        type: LOGOUT_FAILURE,
    };
}

export function logoutUser() {
    return dispatch => {
        dispatch(requestLogout());
        return AuthAction.logoutUser()
            .then(() => {
                dispatch(logoutSuccess());
            })
            .catch(error => {
                if (error.statusCode > 403) {
                    HandleException(error);
                    dispatch(logoutFailure());
                    return;
                }
                dispatch(isUnAuthed());
            });
    };
}

export function setAuthState(authState) {
    return {
        type: SET_AUTH_STATE,
        authState,
    };
}
